import React from "react";

type TitlePaneProps = {
  titleText: string;
};

export const TitlePane: React.FC<TitlePaneProps> = ({ titleText }) => {
  return (
    <div className={"ka-title-block"}>
      <h1>{titleText}</h1>
    </div>
  );
};
