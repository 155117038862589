import { SyntheticEvent, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./InterviewHelper";

export const TabNodeJs = () => {
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    "q-001"
  );

  const handleExpandAccordion =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : false);
    };

  return (
    <div>
      <Accordion
        expanded={expandedAccordion === "q-001"}
        onChange={handleExpandAccordion("q-001")}
      >
        <AccordionSummary aria-controls="q-001-content" id="q-001-header">
          <Typography>What is Node.js ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Node.js is an open-source, cross-platform JavaScript runtime
            environment and library to run web applications outside the client’s
            browser. It is used to create server-side web applications.
            <br />
            <br />
            Node.js is perfect for data-intensive applications as it uses an
            asynchronous, event-driven model. You can use I/O intensive web
            applications like video streaming sites. You can also use it for
            developing: Real-time web applications, Network applications,
            General-purpose applications, and Distributed systems.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-002"}
        onChange={handleExpandAccordion("q-002")}
      >
        <AccordionSummary aria-controls="q-002-content" id="q-002-header">
          <Typography>Why use Node.js ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Node.js makes building scalable network programs easy. Some of its
            advantages include:
          </Typography>
          <ul>
            <li>It is generally fast.</li>
            <li>It rarely blocks.</li>
            <li>It offers a unified programming language and data type.</li>
            <li>Everything is asynchronous.</li>
            <li>It yields great concurrency.</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-003"}
        onChange={handleExpandAccordion("q-003")}
      >
        <AccordionSummary aria-controls="q-003-content" id="q-003-header">
          <Typography>Why is Node.js single-threaded ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Node.js is single-threaded for async processing. By doing async
            processing on a single-thread under typical web loads, more
            performance and scalability can be achieved instead of the typical
            thread-based implementation.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-004"}
        onChange={handleExpandAccordion("q-004")}
      >
        <AccordionSummary aria-controls="q-004-content" id="q-004-header">
          <Typography>
            If Node.js is single-threaded, then how does it handle concurrency ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The Multi-Threaded Request/Response Stateless Model is not followed
            by the Node JS Platform, and it adheres to the Single-Threaded Event
            Loop Model. The Node JS Processing paradigm is heavily influenced by
            the JavaScript Event-based model and the JavaScript callback system.
            As a result, Node.js can easily manage more concurrent client
            requests. The event loop is the processing model's beating heart in
            Node.js.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-005"}
        onChange={handleExpandAccordion("q-005")}
      >
        <AccordionSummary aria-controls="q-005-content" id="q-005-header">
          <Typography>
            What is a <b>callback</b> in Node.js ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A <b>callback</b> function is called after a given task. It allows
            other code to be run in the meantime and prevents any blocking.
            Being an asynchronous platform, Node.js heavily relies on callback.
            All APIs of Node are written to support callbacks.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-006"}
        onChange={handleExpandAccordion("q-006")}
      >
        <AccordionSummary aria-controls="q-006-content" id="q-006-header">
          <Typography>
            What are the advantages of using <b>promises</b> instead of{" "}
            <b>callbacks</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              The control flow of asynchronous logic is more specified and
              structured.
            </li>
            <li>The coupling is low.</li>
            <li>We've built-in error handling.</li>
            <li>Improved readability.</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-007"}
        onChange={handleExpandAccordion("q-007")}
      >
        <AccordionSummary aria-controls="q-007-content" id="q-007-header">
          <Typography>
            How would you define the term <b>I/O</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The term <b>I/O</b> is used to describe any program, operation, or
            device that transfers data to or from a medium and to or from
            another medium.
            <br />
            <br />
            Every transfer is an output from one medium and an input into
            another. The medium can be a physical device, network, or files
            within a system.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-008"}
        onChange={handleExpandAccordion("q-008")}
      >
        <AccordionSummary aria-controls="q-008-content" id="q-008-header">
          <Typography>
            What is <b>NPM</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <b>NPM</b> stands for Node Package Manager, responsible for managing
            all the packages and modules for Node.js.
            <br />
            <br />
            Node Package Manager provides two main functionalities:
          </Typography>
          <ul>
            <li>
              Provides online repositories for node.js packages/modules, which
              are searchable on search.nodejs.org.
            </li>
            <li>
              Provides command-line utility to install Node.js packages and also
              manages Node.js versions and dependencies
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-009"}
        onChange={handleExpandAccordion("q-009")}
      >
        <AccordionSummary aria-controls="q-009-content" id="q-009-header">
          <Typography>What are the modules in Node.js ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Modules are like JavaScript libraries that can be used in a Node.js
            application to include a set of functions. To include a module in a
            Node.js application, use the require() function with the parentheses
            containing the module's name.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-010"}
        onChange={handleExpandAccordion("q-010")}
      >
        <AccordionSummary aria-controls="q-010-content" id="q-010-header">
          <Typography>
            What is the purpose of the <b>module exports</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In Node.js, a module encapsulates all related codes into a single
            unit of code that can be parsed by moving all relevant functions
            into a single file. You may export a module with the module and
            export the function, which lets it be imported into another file
            with a needed keyword.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-011"}
        onChange={handleExpandAccordion("q-011")}
      >
        <AccordionSummary aria-controls="q-011-content" id="q-011-header">
          <Typography>
            What are some of the most commonly used libraries in Node.js ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            There are two commonly used libraries in Node.js:
          </Typography>
          <ul>
            <li>
              <b>ExpressJS</b> - Express is a flexible Node.js web application
              framework that provides a wide set of features to develop web and
              mobile applications.
            </li>
            <li>
              <b>Mongoose</b> - Mongoose is also a Node.js web application
              framework that makes it easy to connect an application to a
              database.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-012"}
        onChange={handleExpandAccordion("q-012")}
      >
        <AccordionSummary aria-controls="q-012-content" id="q-012-header">
          <Typography>What are the pros and cons of Node.js ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label="Pros and cons of Node.js">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Node.js Pros</TableCell>
                  <TableCell align="center">Node.js Cons</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    Fast processing and an event-based model.
                  </TableCell>
                  <TableCell align="center">
                    Not suitable for heavy computational tasks.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    Uses JavaScript, which is well-known amongst developers.
                  </TableCell>
                  <TableCell align="center">
                    Using callback is complex since you end up with several
                    nested callbacks.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    Node Package Manager has over 50,000 packages that provide
                    the functionality to an application.
                  </TableCell>
                  <TableCell align="center">
                    Dealing with relational databases is not a good option for
                    Node.js.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    Best suited for streaming huge amounts of data and I/O
                    intensive operations.
                  </TableCell>
                  <TableCell align="center">
                    Since Node.js is single-threaded, CPU intensive tasks are
                    not its strong suit.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-013"}
        onChange={handleExpandAccordion("q-013")}
      >
        <AccordionSummary aria-controls="q-013-content" id="q-013-header">
          <Typography>What does event-driven programming mean ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            An event-driven programming approach uses events to trigger various
            functions. An event can be anything, such as typing a key or
            clicking a mouse button. A call-back function is already registered
            with the element executes whenever an event is triggered.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-014"}
        onChange={handleExpandAccordion("q-014")}
      >
        <AccordionSummary aria-controls="q-014-content" id="q-014-header">
          <Typography>
            What is an <b>Event Loop</b> in Node.js?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Event loops handle asynchronous callbacks in Node.js. It is the
            foundation of the non-blocking input/output in Node.js, making it
            one of the most important environmental features.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-015"}
        onChange={handleExpandAccordion("q-015")}
      >
        <AccordionSummary aria-controls="q-015-content" id="q-015-header">
          <Typography>
            What is the difference between <b>nextTick()</b> and{" "}
            <b>setImmediate()</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The distinction between method and product. This is accomplished
            through the use of <b>nextTick()</b>and <b>setImmediate()</b>.{" "}
            <b>nextTick()</b> postpones the execution of action until the next
            pass around the event loop, or it simply calls the callback function
            once the event loop's current execution is complete, whereas{" "}
            <b>setImmediate()</b> executes a callback on the next cycle of the
            event loop and returns control to the event loop for any I/O
            operations.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-016"}
        onChange={handleExpandAccordion("q-016")}
      >
        <AccordionSummary aria-controls="q-016-content" id="q-016-header">
          <Typography>
            What is an <b>EventEmitter</b> in Node.js ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <b>EventEmitter</b> is a class that holds all the objects that can
            emit events.
            <br />
            <br />
            Whenever an object from the EventEmitter class throws an event, all
            attached functions are called upon synchronously.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-017"}
        onChange={handleExpandAccordion("q-017")}
      >
        <AccordionSummary aria-controls="q-017-content" id="q-017-header">
          <Typography>
            What are the two types of API functions in Node.js ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Asynchronous, non-blocking functions</li>
            <li>Synchronous, blocking functions</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-018"}
        onChange={handleExpandAccordion("q-018")}
      >
        <AccordionSummary aria-controls="q-018-content" id="q-018-header">
          <Typography>
            What is the <b>package.json</b> file ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The <b>package.json</b> file is the heart of a Node.js system. This
            file holds the metadata for a particular project. The{" "}
            <b>package.json</b> file is found in the root directory of any Node
            application or module
            <br />
            <br />
            This is what a <b>package.json</b> file looks like immediately after
            creating a Node.js project using the command: npm init
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-019"}
        onChange={handleExpandAccordion("q-019")}
      >
        <AccordionSummary aria-controls="q-019-content" id="q-019-header">
          <Typography>What are streams in Node.js ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Streams are objects that enable you to read data or write data
            continuously.
            <br />
            <br />
            There are four types of streams:
          </Typography>
          <ul>
            <li>
              <b>Readable</b> – Used for reading operations
            </li>
            <li>
              <b>Writable</b> − Used for write operations
            </li>
            <li>
              <b>Duplex</b> − Can be used for both reading and write operations
            </li>
            <li>
              <b>Transform</b> − A type of duplex stream where the output is
              computed based on input
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-020"}
        onChange={handleExpandAccordion("q-020")}
      >
        <AccordionSummary aria-controls="q-020-content" id="q-020-header">
          <Typography>
            What is <b>REPL</b> in Node.js?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            REPL stands for Read Eval Print Loop, and it represents a computer
            environment. It’s similar to a Windows console or Unix/Linux shell
            in which a command is entered. Then, the system responds with an
            output.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-021"}
        onChange={handleExpandAccordion("q-021")}
      >
        <AccordionSummary aria-controls="q-021-content" id="q-021-header">
          <Typography>What is the control flow function ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The control flow function is a piece of code that runs in between
            several asynchronous function calls.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-022"}
        onChange={handleExpandAccordion("q-022")}
      >
        <AccordionSummary aria-controls="q-022-content" id="q-022-header">
          <Typography>
            What is the difference between <b>fork()</b> and <b>spawn()</b>{" "}
            methods in Node.js ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label="Pros and cons of Node.js">
              <TableHead>
                <TableRow>
                  <TableCell align="center">fork()</TableCell>
                  <TableCell align="center">spawn()</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    fork() is a particular case of spawn() that generates a new
                    instance of a V8 engine.
                  </TableCell>
                  <TableCell align="left">
                    Spawn() launches a new process with the available set of
                    commands.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    Multiple workers run on a single node code base for multiple
                    tasks.
                  </TableCell>
                  <TableCell align="left">
                    This method does not generate a new V8 instance, and only a
                    single copy of the node module is active on the processor.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-023"}
        onChange={handleExpandAccordion("q-023")}
      >
        <AccordionSummary aria-controls="q-023-content" id="q-023-header">
          <Typography>What is the buffer class in Node.js ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Buffer class stores raw data similar to an array of integers but
            corresponds to a raw memory allocation outside the V8 heap. Buffer
            class is used because pure JavaScript is not compatible with binary
            data.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-024"}
        onChange={handleExpandAccordion("q-024")}
      >
        <AccordionSummary aria-controls="q-024-content" id="q-024-header">
          <Typography>What is piping in Node.js ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Piping is a mechanism used to connect the output of one stream to
            another stream. It is normally used to retrieve data from one stream
            and pass output to another stream.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-025"}
        onChange={handleExpandAccordion("q-025")}
      >
        <AccordionSummary aria-controls="q-025-content" id="q-025-header">
          <Typography>
            What are the different types of HTTP requests ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            HTTP defines a set of request methods used to perform desired
            actions. The request methods include:
          </Typography>
          <ul>
            <li>
              <b>GET:</b> Used to retrieve the data
            </li>
            <li>
              <b>POST:</b> Generally used to make a change in state or reactions
              on the server
            </li>
            <li>
              <b>HEAD:</b> Similar to the GET method, but asks for the response
              without the response body
            </li>
            <li>
              <b>DELETE:</b> Used to delete the predetermined resource
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-026"}
        onChange={handleExpandAccordion("q-026")}
      >
        <AccordionSummary aria-controls="q-026-content" id="q-026-header">
          <Typography>What is a middleware ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Middleware is a function that receives the request and response
            objects.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-027"}
        onChange={handleExpandAccordion("q-027")}
      >
        <AccordionSummary aria-controls="q-027-content" id="q-027-header">
          <Typography>
            What is <b>WASI</b>, and why is it being introduced ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The <b>WASI</b> class implements the WASI system called API and
            extra convenience methods for interacting with WASI-based
            applications. Every WASI instance represents a unique sandbox
            environment. Each WASI instance must specify its command-line
            parameters, environment variables, and sandbox directory structure
            for security reasons.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-028"}
        onChange={handleExpandAccordion("q-028")}
      >
        <AccordionSummary aria-controls="q-028-content" id="q-028-header">
          <Typography>What is callback hell ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Callback hell, also known as the pyramid of doom, is the result of
            intensively nested, unreadable, and unmanageable callbacks, which in
            turn makes the code harder to read and debug.
            <br />
            <br />
            Improper implementation of the asynchronous logic causes callback
            hell.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
