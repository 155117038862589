export const NotFound = () => {
  return (
    <div className={"ka-wrapper ka-bg-wall"}>
      <div className={"ka-not-found-block"}>
        <img
          className={"ka-cup"}
          src={"/assets/ka_404.png"}
          alt={"Page Not Found!"}
        />
        <p className={"ka-message"}>
          Oops! It seems that you got lost. <br />
          Click <a href="./">here</a> to go back to Home.
        </p>
      </div>
    </div>
  );
};
