import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";

type BreadcrumbPaneProps = {
  breadcrumbText: string;
  breadcrumbPrevious?: {
    text: string;
    link: string;
  };
};

export const BreadcrumbPane: React.FC<BreadcrumbPaneProps> = ({
  breadcrumbText,
  breadcrumbPrevious,
}) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" color="inherit" href="./">
        Home
      </Link>
      {breadcrumbPrevious && (
        <Link underline="hover" color="inherit" href={breadcrumbPrevious.link}>
          {breadcrumbPrevious.text}
        </Link>
      )}
      <Typography color="text.primary">{breadcrumbText}</Typography>
    </Breadcrumbs>
  );
};
