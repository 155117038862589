import { BrowserRouter, Routes, Route } from "react-router-dom";
import { muiTheme } from "../setup/muiTheme";
import { ThemeProvider } from "@emotion/react";
import { Home } from "./Home";
import { NotFound } from "./NotFound";
import { Interview } from "./Interview";
import { CV } from "./CV";
import { Snake } from "../applications/snake/Snake";
import "../styles/core.css";

export default function Main() {
  return (
    <ThemeProvider theme={muiTheme}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="interview" element={<Interview />} />
          <Route path="cv" element={<CV />} />
          <Route path="/applications">
            <Route path="*" element={<NotFound />} />
            <Route path="snake" element={<Snake />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
