import { SyntheticEvent, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Card, Grid } from "@mui/material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./InterviewHelper";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

export const TabJavaScript = () => {
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    "q-001"
  );

  const handleExpandAccordion =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : false);
    };

  return (
    <div>
      <Accordion
        expanded={expandedAccordion === "q-001"}
        onChange={handleExpandAccordion("q-001")}
      >
        <AccordionSummary aria-controls="q-001-content" id="q-001-header">
          <Typography>What is JavaScript ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            JavaScript is a lightweight, interpreted programming language with
            object-oriented capabilities that allows you to build interactivity
            into otherwise static HTML pages. The general-purpose core of the
            language has been embedded in Netscape, Internet Explorer, and other
            web browsers.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-002"}
        onChange={handleExpandAccordion("q-002")}
      >
        <AccordionSummary aria-controls="q-002-content" id="q-002-header">
          <Typography>
            What data types are being supported by JavaScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Undefined</li>
            <li>Null</li>
            <li>Boolean</li>
            <li>String</li>
            <li>Symbol</li>
            <li>Number</li>
            <li>Object</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-003"}
        onChange={handleExpandAccordion("q-003")}
      >
        <AccordionSummary aria-controls="q-003-content" id="q-003-header">
          <Typography>
            Is the JavaScript runtime single-threaded or multi-threaded ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            JavaScript is a single-threaded language because while running code
            on a single thread, it can be really easy to implement as we don't
            have to deal with the complicated scenarios that arise in the
            multi-threaded environment like deadlock.
            <br />
            <br />
            JavaScript engine runs on a V8 engine that has a memory heap and a
            call stack. As it is single threaded it means only one statement is
            executed at a time. It is synchronous in nature.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-004"}
        onChange={handleExpandAccordion("q-004")}
      >
        <AccordionSummary aria-controls="q-004-content" id="q-004-header">
          <Typography>What is an anonymous function ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            An anonymous function is a function without a name. An anonymous
            function is not accessible after its initial creation. Therefore,
            you often need to assign it to a variable.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-005"}
        onChange={handleExpandAccordion("q-005")}
      >
        <AccordionSummary aria-controls="q-005-content" id="q-005-header">
          <Typography>What is a self-invoking function ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A self-invoking is a function that is defined and then invoked.
            However, if there is a need to automatically execute a function at
            the place where it is given and not be called again, then anonymous
            functions can be used. Such functions have no name, and thus the
            name.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-006"}
        onChange={handleExpandAccordion("q-006")}
      >
        <AccordionSummary aria-controls="q-006-content" id="q-006-header">
          <Typography>
            What is the arrow function (Lambda expression) ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In ES6 we have arrow functions (Lambda expressions) with a more
            flexible syntax. Lambda expressions are sometimes called arrow
            functions because they are written using arrows. As a result of
            using the lambda expression, the 8-character “function” is now a
            2-character {'"=>"'}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-007"}
        onChange={handleExpandAccordion("q-007")}
      >
        <AccordionSummary aria-controls="q-007-content" id="q-007-header">
          <Typography>
            What are <b>var</b>, <b>let</b>, and <b>const</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            They are being used to define the variables.
            <br />
            <br />
            Before the advent of ES6, <b>var</b> declarations ruled. There are
            issues associated with variables declared with <b>var</b>, though.
            That is why it was necessary for new ways to declare variables to
            emerge.
            <br />
            <br />
            <b>let</b> is now preferred for variable declaration. It's no
            surprise as it comes as an improvement to <b>var</b> declarations.{" "}
            <b>let</b> is block scoped and it can be updated, but not
            re-declared (unlike <b>var</b>, a <b>let</b> variable cannot be
            re-declared within its scope).
            <br />
            <br />
            Variables declared with the <b>const</b> maintain constant values.{" "}
            <b>const</b> declarations share some similarities with <b>let</b>{" "}
            declarations. Like <b>let</b> declarations, <b>const</b>{" "}
            declarations can only be accessed within the block they were
            declared.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-008"}
        onChange={handleExpandAccordion("q-008")}
      >
        <AccordionSummary aria-controls="q-008-content" id="q-008-header">
          <Typography>What is hoisting ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Hoisting is JavaScript's default behavior of moving all declarations
            to the top of the current scope (to the top of the current script or
            the current function).
            <br />
            <br />
            Variables defined with <b>let</b> and <b>const</b> are hoisted to
            the top of the block, but not initialized. The block of code is
            aware of the variable, but it cannot be used until it has been
            declared. JavaScript only hoists declarations, not initializations.
            <br />
            <br />
            Using a <b>let</b> variable before it is declared will result in a{" "}
            <b>Reference Error</b>.
            <br />
            Using a <b>const</b> variable before it is declared, is a{" "}
            <b>Syntax Error</b>, therefore the code will simply not run.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-009"}
        onChange={handleExpandAccordion("q-009")}
      >
        <AccordionSummary aria-controls="q-009-content" id="q-009-header">
          <Typography>
            How would you get an internal representation in milliseconds of a
            Date ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>You could do that by using Date.parse().</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-010"}
        onChange={handleExpandAccordion("q-010")}
      >
        <AccordionSummary aria-controls="q-010-content" id="q-010-header">
          <Typography>
            What are the <b>escape()</b> and <b>unescape()</b> functions ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The escape() function allows for converting a string into a coded
            form in JavaScript. It is used for securely transferring information
            from one system to another over some network. The unescape()
            function does the exact opposite of the escape() function, i.e., it
            decodes a coded string into the original string.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-011"}
        onChange={handleExpandAccordion("q-011")}
      >
        <AccordionSummary aria-controls="q-011-content" id="q-011-header">
          <Typography>What is mutation ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Mutation means a change in the form or nature of the original data.
            In JavaScript, there are two data types: 1) primitive and 2)
            non-primitive/reference data types. Reference types in JavaScript
            are mutable, meaning that the state and fields of mutable types can
            be changed. No new instance is created as a result.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-012"}
        onChange={handleExpandAccordion("q-012")}
      >
        <AccordionSummary aria-controls="q-012-content" id="q-012-header">
          <Typography>What is variable scope ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In JavaScript, we use scope as a way to identify where and whether
            we can use a variable. The variables may exist within a block,
            inside a function, or outside a function and block.
            <br />
            <br />
            There are mainly three types of scope:
          </Typography>
          <ul>
            <li>Block Scope</li>
            <li>Functional Scope</li>
            <li>Global Scope</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-013"}
        onChange={handleExpandAccordion("q-013")}
      >
        <AccordionSummary aria-controls="q-013-content" id="q-013-header">
          <Typography>What is a closure ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A closure is the combination of a function bundled together
            (enclosed) with references to its surrounding state (the lexical
            environment). In other words, a closure gives you access to an outer
            function's scope from an inner function. In JavaScript, closures are
            created every time a function is created, at function creation time.
            <br />
            <br />
            We need closures when a variable which is defined outside the scope
            in reference is accessed from some inner scope.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-014"}
        onChange={handleExpandAccordion("q-014")}
      >
        <AccordionSummary aria-controls="q-014-content" id="q-014-header">
          <Typography>
            What is the difference between <b>==</b> and <b>===</b> operators ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The <b>==</b> operator checks equality only whereas <b>===</b>{" "}
            checks equality, and data type, i.e., a value must be of the same
            type.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-015"}
        onChange={handleExpandAccordion("q-015")}
      >
        <AccordionSummary aria-controls="q-015-content" id="q-015-header">
          <Typography>
            What is the difference between <b>undefined</b> value and{" "}
            <b>null</b> value ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <b>Undefined value:</b> A value that is not defined and has no
            keyword is known as undefined value.
            <br />
            <br />
            <b>Null value:</b> A value that is explicitly specified by the
            keyword "null" is known as a null value.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-016"}
        onChange={handleExpandAccordion("q-016")}
      >
        <AccordionSummary aria-controls="q-016-content" id="q-016-header">
          <Typography>What is negative infinity ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Negative Infinity is a number in JavaScript which can be derived by
            dividing the negative number by zero.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-017"}
        onChange={handleExpandAccordion("q-017")}
      >
        <AccordionSummary aria-controls="q-017-content" id="q-017-header">
          <Typography>How to handle exceptions in JavaScript ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            By the help of try/catch block, we can handle exceptions in
            JavaScript. JavaScript supports try, catch, finally and throw
            keywords for exception handling.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-018"}
        onChange={handleExpandAccordion("q-018")}
      >
        <AccordionSummary aria-controls="q-018-content" id="q-018-header">
          <Typography>
            What is the use of <b>Set</b> object and <b>WeakSet</b> object in
            JavaScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <b>Set:</b> The JavaScript Set object is used to store the elements
            with unique values. The values can be of any type i.e. whether
            primitive values or object references.
            <br />
            <br />
            <b>WeakSet:</b> The JavaScript WeakSet object is the type of
            collection that allows us to store weakly held objects. Unlike Set,
            the WeakSet are the collections of objects only. It doesn't contain
            the arbitrary values.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-019"}
        onChange={handleExpandAccordion("q-019")}
      >
        <AccordionSummary aria-controls="q-019-content" id="q-019-header">
          <Typography>What is JSON ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            JSON stands for <b>J</b>ava<b>S</b>cript <b>O</b>bject <b>N</b>
            otation.
            <br />
            <br />
            It is a lightweight format for storing and transporting data,
            therefore it is often used when data is sent from a server to a
            Web/Mobile application.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-020"}
        onChange={handleExpandAccordion("q-020")}
      >
        <AccordionSummary aria-controls="q-020-content" id="q-020-header">
          <Typography>What are promises ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Promises are used to handle asynchronous operations in JavaScript.
            They are easy to manage when dealing with multiple asynchronous
            operations where callbacks can create callback hell leading to
            unmanageable code.
            <br />
            <br />
            Prior to promises events and callback functions were used but they
            had limited functionalities and created unmanageable code. Multiple
            callback functions would create callback hell that leads to
            unmanageable code. Also it is not easy for any user to handle
            multiple callbacks at the same time.
            <br />
            <br />
            Promises are the ideal choice for handling asynchronous operations
            in the simplest manner. They can handle multiple asynchronous
            operations easily and provide better error handling than callbacks
            and events. In other words also, we may say that, promises are the
            ideal choice for handling multiple callbacks at the same time, thus
            avoiding the undesired callback hell situation. Promises do provide
            a better chance to a user to read the code in a more effective and
            efficient manner especially it that particular code is used for
            implementing multiple asynchronous operations.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-021"}
        onChange={handleExpandAccordion("q-021")}
      >
        <AccordionSummary aria-controls="q-021-content" id="q-021-header">
          <Typography>What is a pure function ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A pure function is a deterministic function. This means when a same
            input is passed every time, the function will return same output. In
            mathematical terms it is nothing but a well defined function.
            <br />
            <br />A pure function will have the following properties:
          </Typography>
          <ul>
            <li>It depends only on its own arguments.</li>
            <li>It wont try to change variables out of its scope.</li>
            <li>It doesn't produce any side effects.</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-022"}
        onChange={handleExpandAccordion("q-022")}
      >
        <AccordionSummary aria-controls="q-022-content" id="q-022-header">
          <Typography>
            What is the difference between <b>Object.freeze()</b> and{" "}
            <b>const</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <b>const</b> applies to bindings ("variables"). It creates an
            immutable binding, i.e. you cannot assign a new value to the
            binding.
            <br />
            <br />
            <b>Object.freeze</b> works on values, and more specifically, object
            values. It makes an object immutable, i.e. you cannot change its
            properties.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-023"}
        onChange={handleExpandAccordion("q-023")}
      >
        <AccordionSummary aria-controls="q-023-content" id="q-023-header">
          <Typography>What is JavaScript Prototype ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            All JavaScript objects inherit properties and methods from a
            prototype.
            <br />
            <br />
            The prototype is an object that is associated with every functions
            and objects by default in JavaScript, where function's prototype
            property is accessible and modifiable and object's prototype
            property (aka attribute) is not visible.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-024"}
        onChange={handleExpandAccordion("q-024")}
      >
        <AccordionSummary aria-controls="q-024-content" id="q-024-header">
          <Typography>What is currying function ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A currying function is the process of taking a function with
            multiple arguments and turning it into a sequence of functions each
            with a single argument.
            <br />
            <br />
            Curried functions are a great way to improve code reusability and
            functional composition.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-025"}
        onChange={handleExpandAccordion("q-025")}
      >
        <AccordionSummary aria-controls="q-025-content" id="q-025-header">
          <Typography>What is event delegation ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Event delegation is a pattern of adding a single event listener to a
            parent element instead of multiple elements.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-026"}
        onChange={handleExpandAccordion("q-026")}
      >
        <AccordionSummary aria-controls="q-026-content" id="q-026-header">
          <Typography>
            What are some ways to handle asynchronous operation in JavaScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              <b>Callback</b> is a function that is used to notify the calling
              instance.
            </li>
            <li>
              <b>Promise</b> is an object representing the eventual completion
              or failure of an asynchronous operation. A pending promise can
              either be fulfilled with a value or rejected with a reason.
              Callbacks are attached to the returned promises that make handling
              of asynchronous code easier and more readable.
            </li>
            <li>
              <b>async/await</b> is a new addition to ES2017 which is syntactic
              sugar on top of promises and make asynchronous code look
              synchronous code.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-027"}
        onChange={handleExpandAccordion("q-027")}
      >
        <AccordionSummary aria-controls="q-027-content" id="q-027-header">
          <Typography>
            What is the purpose of <b>Function.prototype.bind</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The <b>bind()</b> method creates a new function that, when called,
            has its this keyword set to the provided value, with a given
            sequence of arguments preceding any provided when the new function
            is called.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-028"}
        onChange={handleExpandAccordion("q-028")}
      >
        <AccordionSummary aria-controls="q-028-content" id="q-028-header">
          <Typography>What is Generator in JavaScript ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Generators are functions which can be exited and later re-entered.
            Their context (variable bindings) will be saved across re-entrances.
            Generator functions are written using the function* syntax. When
            called initially, generator functions do not execute any of their
            code, instead returning a type of iterator called a Generator. When
            a value is consumed by calling the generator’s next method, the
            Generator function executes until it encounters the yield keyword.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-029"}
        onChange={handleExpandAccordion("q-029")}
      >
        <AccordionSummary aria-controls="q-029-content" id="q-029-header">
          <Typography>What is memoization ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Memoization is an optimization technique by storing the result of
            expensive function calls and returning the cached results when the
            same inputs occur again.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-030"}
        onChange={handleExpandAccordion("q-030")}
      >
        <AccordionSummary aria-controls="q-030-content" id="q-030-header">
          <Typography>
            What are the differences between <b>call</b>, <b>apply</b>, and{" "}
            <b>bind</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <b>call</b> and <b>apply</b> immediately calls a function while{" "}
            <b>bind</b> creates a new function that can be invoked in the
            future. Arguments with <b>call</b> are passed in one by one,
            separated with a comma while <b>apply</b> expects an array as its
            argument.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <h3 className={"centered"}>What is the output of the following ?</h3>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4}>
            <Card variant="outlined" className={"ka-interview-card"}>
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                className={"ka-syntax-block"}
              >
                {
                  "let x = 20;\nlet y = 50;\n\nconsole.log('result is ' + x + y);"
                }
              </SyntaxHighlighter>
              <p>
                <b>
                  <u>Output</u>
                </b>
                <br />
                result is 2050
              </p>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card variant="outlined" className={"ka-interview-card"}>
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                className={"ka-syntax-block"}
              >
                {
                  "console.log(squareFunc(3));\n\nfunction squareFunc(x) {\n  return x * x;\n};"
                }
              </SyntaxHighlighter>
              <p>
                <b>
                  <u>Output</u>
                </b>
                <br />9
              </p>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card variant="outlined" className={"ka-interview-card"}>
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                className={"ka-syntax-block"}
              >
                {
                  "function xFunc() { \n  return (() => 0)(); \n} \n \nconsole.log(typeof xFunc());"
                }
              </SyntaxHighlighter>
              <p>
                <b>
                  <u>Output</u>
                </b>
                <br />
                number
              </p>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card variant="outlined" className={"ka-interview-card"}>
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                className={"ka-syntax-block"}
              >
                {
                  "let output = (function(x) {\n  delete x;\n  return x;\n})(13);\n\nconsole.log(output);"
                }
              </SyntaxHighlighter>
              <p>
                <b>
                  <u>Output</u>
                </b>
                <br />
                13
              </p>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card variant="outlined" className={"ka-interview-card"}>
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                className={"ka-syntax-block"}
              >
                {
                  "let x = 0;\n\nconsole.log(x++);\nconsole.log(++x);\nconsole.log(x);"
                }
              </SyntaxHighlighter>
              <p>
                <b>
                  <u>Output</u>
                </b>
                <br />
                0
                <br />
                2
                <br />2
              </p>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card variant="outlined" className={"ka-interview-card"}>
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                className={"ka-syntax-block"}
              >
                {
                  "let Car = {\n  make: 'Ford'\n};\nlet newCar = Object.create(Car);\ndelete newCar.make;\n\nconsole.log(newCar.make);"
                }
              </SyntaxHighlighter>
              <p>
                <b>
                  <u>Output</u>
                </b>
                <br />
                Ford
              </p>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card variant="outlined" className={"ka-interview-card"}>
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                className={"ka-syntax-block"}
              >
                {
                  "function yFunc() {\n  console.log(x);\n  console.log(y);\n  var x = 'Demo';\n  let y = 2000;\n}\n\nyFunc();"
                }
              </SyntaxHighlighter>
              <p>
                <b>
                  <u>Output</u>
                </b>
                <br />
                undefined
                <br />
                ReferenceError
              </p>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card variant="outlined" className={"ka-interview-card"}>
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                className={"ka-syntax-block"}
              >
                {
                  "const objectA = {\n  a: 51,\n  b: 3,\n  c: 7\n};\nconst objectB = Object.assign({\n  c: 5,\n  d: 27\n}, objectA);\n\nconsole.log(objectB.c, objectB.d);"
                }
              </SyntaxHighlighter>
              <p>
                <b>
                  <u>Output</u>
                </b>
                <br />
                7, 27
              </p>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card variant="outlined" className={"ka-interview-card"}>
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                className={"ka-syntax-block"}
              >
                {
                  "const p = new Promise(t => t(4));\np.then(r => {\n  console.log(r);\n  return r * 5;\n}).then(r => {\n  console.log(r);\n  return r * 2;\n}).finally(r => {\n  console.log(r);\n  return r * 10;\n}).then(r => {\n  console.log(r);\n});"
                }
              </SyntaxHighlighter>
              <p>
                <b>
                  <u>Output</u>
                </b>
                <br />
                4
                <br />
                20
                <br />
                undefined
                <br />
                40
              </p>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
