import React from "react";
import ReactDOM from "react-dom/client";
import Main from "./pages/Main";

const root = ReactDOM.createRoot(
  document.getElementById("karmikal-apps") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);
