export const Home = () => {
  return (
    <div className={"ka-wrapper"}>
      <div className={"ka-logo-block"}>
        <img
          className={"ka-logo"}
          src={"/assets/logo/logo.png"}
          alt={"Karmikal Apps"}
        />
      </div>
    </div>
  );
};
