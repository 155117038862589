import { createTheme } from "@mui/material/styles";

export const muiTheme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#8f0404",
      main: "#8f0404",
      contrastText: "#111111",
    },
    secondary: {
      light: "#111111",
      main: "#111111",
      contrastText: "#8f0404",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    allVariants: {
      fontFamily: "Roboto Condensed",
    },
  },
});
