import React, {useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import {BiChevronLeft, BiChevronRight} from "react-icons/bi";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const CV = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = (document: any) => {
        setNumPages(document?._pdfInfo?.numPages);
    }

    const onNavigatePage = (e: React.MouseEvent<HTMLElement>, target: string) => {
        e.preventDefault();

        switch (target) {
            case "previous":
                pageNumber > 1 && setPageNumber(pageNumber - 1);
                break;
            case "next":
                numPages && pageNumber < numPages && setPageNumber(pageNumber + 1);
                break;
            default:
                break;
        }
    }

    return (
        <div className={"ka-wrapper ka-bg-tiles"}>
            <div className={"ka-pdf-container"}>
                <h1 className={"ka-pdf-title"}>
                    Curriculum Vitae
                </h1>
                <div className={"ka-pdf-update"}>
                    <p>
                        <span>Last update:</span> 23th of October, 2022
                    </p>
                </div>
                <div className={"ka-pdf-download"}>
                    <a href={"../documents/Curriculum_Vitae.pdf"} download={"Curriculum_Vitae.pdf"}>Download PDF</a>
                </div>
                <div className={numPages ? "ka-pdf loaded" : "ka-pdf"}>
                    <Document file={'../documents/Curriculum_Vitae.pdf'} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page height={1000} pageNumber={pageNumber}/>
                    </Document>
                </div>
                <div className={numPages ? "ka-pdf-pagination loaded" : "ka-pdf-pagination"}>
                    <div className={"ka-pagination-navigation"}>
                        <a href="#" className={pageNumber === 1 ? "inactive" : "active"} onClick={(e) => {
                            onNavigatePage(e, "previous")
                        }}>
                            <BiChevronLeft/>
                        </a>
                        <a href="#" className={pageNumber === numPages ? "inactive" : "active"} onClick={(e) => {
                            onNavigatePage(e, "next")
                        }}>
                            <BiChevronRight/>
                        </a>
                    </div>
                    <p className={"ka-pdf-pagination-pages"}>
                        Page {pageNumber} of {numPages}
                    </p>
                </div>
            </div>
        </div>
    )
};