import { useEffect, useState } from "react";
import { Canvas } from "../../components/Canvas";

class SnakeSegment {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

type GameState = {
  speed: number;
  tileCount: number;
  tileSize: number;
  snakeHeadX: number;
  snakeHeadY: number;
  snakeSegments: Array<SnakeSegment>;
  snakeTailSegments: number;
  appleX: number;
  appleY: number;
  score: number;
  gameOver: boolean;
  stepsTaken: number;
  lastScoreDifficultyIncrease: number;
};

export const Snake: React.FC = () => {
  const BOARD_SIZE: number = 420;
  const RESAPWN_APPLE_STEPS: number = 60;
  const DIFFICULTY_INCREASE_SCORE_FACTOR: number = 4;

  const sfx_munch = new Audio("../assets/applications/audio/snake/munch.wav");
  const sfx_squelch = new Audio(
    "../assets/applications/audio/snake/squelch.wav"
  );

  const DEFAULT_GAME_STATE: GameState = {
    speed: 6,
    tileCount: 20,
    tileSize: BOARD_SIZE / 20 - 1.5,
    snakeHeadX: 10,
    snakeHeadY: 10,
    snakeSegments: [],
    snakeTailSegments: 2,
    appleX: 0,
    appleY: 0,
    score: 0,
    gameOver: false,
    stepsTaken: RESAPWN_APPLE_STEPS,
    lastScoreDifficultyIncrease: 0,
  };

  let gameState: GameState = structuredClone(DEFAULT_GAME_STATE);

  let inputVelocityX: number = 0;
  let inputVelocityY: number = 0;

  let velocityX: number = 0;
  let velocityY: number = 0;

  const clearCanvas = (context: CanvasRenderingContext2D) => {
    context.fillStyle = "#321933";
    context.fillRect(0, 0, BOARD_SIZE, BOARD_SIZE);
  };

  const restartGame = () => {
    gameState = structuredClone(DEFAULT_GAME_STATE);

    inputVelocityX = 0;
    inputVelocityY = 0;

    velocityX = 0;
    velocityY = 0;
  };

  const updateSnakePosition = () => {
    gameState.snakeHeadX += velocityX;
    gameState.snakeHeadY += velocityY;

    if (velocityX !== 0 || velocityY !== 0) {
      gameState.stepsTaken++;
    }
  };

  const drawSnake = (context: CanvasRenderingContext2D) => {
    context.fillStyle = "#75C831";
    for (let i = 0; i < gameState.snakeSegments.length; i++) {
      let segment: SnakeSegment = gameState.snakeSegments[i];
      context.fillRect(
        segment.x * gameState.tileCount,
        segment.y * gameState.tileCount,
        gameState.tileSize,
        gameState.tileSize
      );
    }

    gameState.snakeSegments.push(
      new SnakeSegment(gameState.snakeHeadX, gameState.snakeHeadY)
    );

    while (gameState.snakeSegments.length > gameState.snakeTailSegments) {
      gameState.snakeSegments.shift();
    }

    context.fillStyle = "#F5C831";
    context.fillRect(
      gameState.snakeHeadX * gameState.tileCount,
      gameState.snakeHeadY * gameState.tileCount,
      gameState.tileSize,
      gameState.tileSize
    );

    context.fillStyle = "#321933";
    context.fillRect(
      gameState.snakeHeadX * gameState.tileCount + 4,
      gameState.snakeHeadY * gameState.tileCount + 4,
      gameState.tileSize - 8,
      gameState.tileSize - 8
    );
  };

  const spawnRandomApple = () => {
    if (gameState.stepsTaken === RESAPWN_APPLE_STEPS) {
      generateAppleSpawnPoint();
      gameState.stepsTaken = 0;
    }
  };

  const drawApple = async (context: CanvasRenderingContext2D) => {
    const image_apple: any = document.getElementById("image-apple");

    context.drawImage(
      image_apple,
      gameState.appleX * gameState.tileCount,
      gameState.appleY * gameState.tileCount,
      gameState.tileSize,
      gameState.tileSize
    );
  };

  const drawScore = (context: CanvasRenderingContext2D) => {
    context.fillStyle = "#FFFFFF";
    context.font = "16px Roboto Condensed";
    context.fillText("Score: " + gameState.score, 10, BOARD_SIZE - 10);
  };

  const drawGame = (context: CanvasRenderingContext2D) => {
    velocityX = inputVelocityX;
    velocityY = inputVelocityY;

    spawnRandomApple();
    updateSnakePosition();

    !gameState.gameOver && checkIfGameOver();

    if (gameState.gameOver) {
      clearCanvas(context);

      drawScore(context);
      drawGameOver(context);
    } else {
      clearCanvas(context);

      checkAndHandleCollisionWithApple();

      drawApple(context);
      drawSnake(context);

      handleDifficultyIncrease();
      drawScore(context);
    }

    setTimeout(() => drawGame(context), 1000 / gameState.speed);
  };

  const generateAppleSpawnPoint = () => {
    let safeSpawnPoint = false;
    while (!safeSpawnPoint) {
      let inconsistencyFound = false;
      gameState.appleX = Math.floor(Math.random() * gameState.tileCount);
      gameState.appleY = Math.floor(Math.random() * gameState.tileCount);

      for (let i = 0; i < gameState.snakeSegments.length; i++) {
        let part: SnakeSegment = gameState.snakeSegments[i];

        if (part.x === gameState.appleX && part.y === gameState.appleY) {
          inconsistencyFound = true;
          break;
        }
      }

      if (
        !inconsistencyFound &&
        gameState.snakeHeadX === gameState.appleX &&
        gameState.snakeHeadY === gameState.appleY
      ) {
        inconsistencyFound = true;
      }

      if (!inconsistencyFound) {
        safeSpawnPoint = true;
      }
    }
  };

  const checkAndHandleCollisionWithApple = () => {
    if (
      gameState.appleX === gameState.snakeHeadX &&
      gameState.appleY === gameState.snakeHeadY
    ) {
      sfx_munch.play();
      generateAppleSpawnPoint();
      gameState.stepsTaken = 0;
      gameState.snakeTailSegments++;
      gameState.score++;
    }
  };

  const checkIfGameOver = () => {
    if (velocityX === 0 && velocityY === 0) {
      gameState.gameOver = false;
      return;
    }

    if (
      gameState.snakeHeadX < 0 ||
      gameState.snakeHeadY < 0 ||
      gameState.snakeHeadX > gameState.tileCount ||
      gameState.snakeHeadY > gameState.tileCount
    ) {
      gameState.gameOver = true;
      sfx_squelch.play();
    }

    for (let i = 0; i < gameState.snakeSegments.length; i++) {
      let part: SnakeSegment = gameState.snakeSegments[i];

      if (part.x === gameState.snakeHeadX && part.y === gameState.snakeHeadY) {
        gameState.gameOver = true;
        sfx_squelch.play();
        break;
      }
    }
  };

  const drawGameOver = (context: CanvasRenderingContext2D) => {
    context.fillStyle = "#44A9C7";
    context.font = "34px Roboto Condensed";
    context.fillText("Game Over!", BOARD_SIZE / 3.25, BOARD_SIZE / 2);
  };

  const handleDifficultyIncrease = () => {
    if (
      gameState.score - gameState.lastScoreDifficultyIncrease ===
      DIFFICULTY_INCREASE_SCORE_FACTOR
    ) {
      gameState.lastScoreDifficultyIncrease = gameState.score;
      gameState.speed++;
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.keyCode) {
      case 38:
      case 87:
        if (inputVelocityY == 1) {
          return;
        }
        inputVelocityX = 0;
        inputVelocityY = -1;
        break;
      case 40:
      case 83:
        if (inputVelocityY == -1) {
          return;
        }
        inputVelocityX = 0;
        inputVelocityY = 1;
        break;
      case 37:
      case 65:
        if (inputVelocityX == 1) {
          return;
        }
        inputVelocityX = -1;
        inputVelocityY = 0;
        break;
      case 39:
      case 68:
        if (inputVelocityX == -1) {
          return;
        }
        inputVelocityX = 1;
        inputVelocityY = 0;
        break;
      case 82:
        restartGame();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div className={"ka-wrapper ka-bg-tiles"}>
      <div className={"ka-application-container"}>
        <h1 className={"ka-title"}>Snake</h1>
        <Canvas
          draw={drawGame}
          width={BOARD_SIZE}
          height={BOARD_SIZE}
          style={{
            display: "block",
            margin: "10px auto 0",
          }}
        />
        <div className="ka-hidden-content">
          <img
            id="image-apple"
            src={"../assets/applications/graphics/snake/apple.png"}
          />
        </div>
        <div className="ka-legend-container">
          <p>Controls:</p>
          <div className="ka-legend">
            <div className="ka-legend-note">
              <span>Arrow Up</span> / <span>W</span> - Move Up
            </div>
            <div className="ka-legend-note">
              <span>Arrow Down</span> / <span>S</span> - Move Down
            </div>
            <div className="ka-legend-note">
              <span>Arrow Left</span> / <span>A</span> - Move Left
            </div>
            <div className="ka-legend-note">
              <span>Arrow Right</span> / <span>D</span> - Move Right
            </div>
            <div className="ka-legend-note">
              <span>R</span> - Restart Game
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
