import { SyntheticEvent, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./InterviewHelper";

export const TabGit = () => {
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    "q-001"
  );

  const handleExpandAccordion =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : false);
    };

  return (
    <div>
      <Accordion
        expanded={expandedAccordion === "q-001"}
        onChange={handleExpandAccordion("q-001")}
      >
        <AccordionSummary aria-controls="q-001-content" id="q-001-header">
          <Typography>What is Git ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Git is a version control system for tracking changes in computer
            files and is used to help coordinate work among several people on a
            project while tracking progress over time. In other words, it’s a
            tool that facilitates source code management in software
            development.
            <br />
            <br />
            Git favors both programmers and non-technical users by keeping track
            of their project files. It enables multiple users to work together
            and handles large projects efficiently.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-002"}
        onChange={handleExpandAccordion("q-002")}
      >
        <AccordionSummary aria-controls="q-002-content" id="q-002-header">
          <Typography>
            What do you understand by the term <b>Version Control System</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A <b>Version Control System</b> (VCS) records all the changes made
            to a file or set of data, so a specific version may be called later
            if needed.
            <br />
            <br />
            This helps ensure that all team members are working on the latest
            version of the file
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-003"}
        onChange={handleExpandAccordion("q-003")}
      >
        <AccordionSummary aria-controls="q-003-content" id="q-003-header">
          <Typography>
            What is the difference between Git and GitHub?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label="Differences between Git and GitHub">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Git</TableCell>
                  <TableCell align="center">GitHub</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">Git is a software.</TableCell>
                  <TableCell align="center">GitHub is a service.</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    Git can be installed locally on the system.
                  </TableCell>
                  <TableCell align="center">
                    GitHub is hosted on the web.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    Provides a desktop interface called git GUI.
                  </TableCell>
                  <TableCell align="center">
                    Provides a desktop interface called GitHub Desktop.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    It does not support user management features.
                  </TableCell>
                  <TableCell align="center">
                    Provides built-in user management.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-004"}
        onChange={handleExpandAccordion("q-004")}
      >
        <AccordionSummary aria-controls="q-004-content" id="q-004-header">
          <Typography>What is a Git repository ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Git repository refers to a place where all the Git files are stored.
            These files can either be stored on the local repository or on the
            remote repository.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-005"}
        onChange={handleExpandAccordion("q-005")}
      >
        <AccordionSummary aria-controls="q-005-content" id="q-005-header">
          <Typography>
            Could you name a few Git commands with their function ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              <b>git config</b> - Configure the username and email address
            </li>
            <li>
              <b>git add</b> - Add one or more files to the staging area
            </li>
            <li>
              <b>git diff</b> - View the changes made to the file
            </li>
            <li>
              <b>git init</b> - Initialize an empty Git repository
            </li>
            <li>
              <b>git commit</b> - Commit changes to head but not to the remote
              repository
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-006"}
        onChange={handleExpandAccordion("q-006")}
      >
        <AccordionSummary aria-controls="q-006-content" id="q-006-header">
          <Typography>What are the advantages of using Git ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Faster release cycles.</li>
            <li>Easy team collaboration.</li>
            <li>Widespread acceptance.</li>
            <li>Maintains the integrity of source code.</li>
            <li>Pull requests.</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-007"}
        onChange={handleExpandAccordion("q-007")}
      >
        <AccordionSummary aria-controls="q-007-content" id="q-007-header">
          <Typography>
            What is the correct syntax to add a message to a commit ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>git commit -m "COMMIT MESSAGE"</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-008"}
        onChange={handleExpandAccordion("q-008")}
      >
        <AccordionSummary aria-controls="q-008-content" id="q-008-header">
          <Typography>
            What does the <b>git push</b> command do ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The <b>git push</b> command is used to push the content in a local
            repository to a remote repository. After a local repository has been
            modified, a push is executed to share the modifications with remote
            team members.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-009"}
        onChange={handleExpandAccordion("q-009")}
      >
        <AccordionSummary aria-controls="q-009-content" id="q-009-header">
          <Typography>
            What are the differences between <b>git fetch</b> and{" "}
            <b>git pull</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label="Differences between Git and GitHub">
              <TableHead>
                <TableRow>
                  <TableCell align="center">git fetch</TableCell>
                  <TableCell align="center">git pull</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    The Git fetch command only downloads new data from a remote
                    repository.
                  </TableCell>
                  <TableCell align="center">
                    Git pull updates the current HEAD branch with the latest
                    changes from the remote server.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    It does not integrate any of these new data into your
                    working files.
                  </TableCell>
                  <TableCell align="center">
                    Downloads new data and integrate it with the current working
                    files.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    Command - git fetch origin
                    <br />
                    <br />
                    git fetch --all
                  </TableCell>
                  <TableCell align="center">
                    Tries to merge remote changes with your local ones.
                    <br />
                    <br />
                    Command - git pull origin master
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-010"}
        onChange={handleExpandAccordion("q-010")}
      >
        <AccordionSummary aria-controls="q-010-content" id="q-010-header">
          <Typography>
            What do you understand about the Git merge conflict ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A Git merge conflict is an event that occurs when Git is unable to
            resolve the differences in code between the two commits
            automatically.
            <br />
            <br />
            Git is capable of automatically merging the changes only if the
            commits are on different lines or branches.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-011"}
        onChange={handleExpandAccordion("q-011")}
      >
        <AccordionSummary aria-controls="q-011-content" id="q-011-header">
          <Typography>How do you resolve conflicts in Git ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Here are the steps that will help you resolve conflicts in Git:
          </Typography>
          <ul>
            <li>Identify the files responsible for the conflicts.</li>
            <li>Implement the desired changes to the files.</li>
            <li>Add the files using the git add command.</li>
            <li>
              The last step is to commit the changes in the file with the help
              of the git commit command.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-012"}
        onChange={handleExpandAccordion("q-012")}
      >
        <AccordionSummary aria-controls="q-012-content" id="q-012-header">
          <Typography>
            What do you understand about the <b>Staging Area</b> in Git ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The <b>Staging Area</b> in Git is when it starts to track and save
            the changes that occur in files. These saved changes reflect in the
            .git directory. Staging is an intermediate area that helps to format
            and review commits before their completion.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-013"}
        onChange={handleExpandAccordion("q-013")}
      >
        <AccordionSummary aria-controls="q-013-content" id="q-013-header">
          <Typography>
            What is the purpose of <b>git stash</b> command ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Let’s say you're a developer and you want to switch branches to work
            on something else. The issue is you don’t want to make commits in
            uncompleted work, so you just want to get back to this point later.
            The solution here is the <b>git stash</b>.
            <br />
            <br />
            <b>git stash</b> takes your modified tracked files and saves it on a
            stack of unfinished changes that you can reapply at any time. To go
            back to the work you can use the stash pop.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-014"}
        onChange={handleExpandAccordion("q-014")}
      >
        <AccordionSummary aria-controls="q-014-content" id="q-014-header">
          <Typography>
            What is the use of the <b>git config</b> command ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The <b>git config</b> command is used to set git configuration
            values on a global or local level. It alters the configuration
            options in your git installation. It is generally used to set your
            Git email, editor, and any aliases you want to use with the git
            command.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-015"}
        onChange={handleExpandAccordion("q-015")}
      >
        <AccordionSummary aria-controls="q-015-content" id="q-015-header">
          <Typography>
            What is the functionality of <b>git clean</b> command ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The <b>git clean</b> command removes the untracked files from the
            working directory.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-016"}
        onChange={handleExpandAccordion("q-016")}
      >
        <AccordionSummary aria-controls="q-016-content" id="q-016-header">
          <Typography>
            What has to be run to squash the last N commits into a single commit
            ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In Git, squashing commits means combining two or more commits into
            one.
            <br />
            <br />
            Use the below command to write a new commit message from the
            beginning.
            <br />
            <b>{"git reset -soft HEAD~N && git commit"}</b>
            <br />
            <br />
            But, if you want to edit a new commit message and add the existing
            commit messages, then you must extract the messages and pass them to
            Git commit.
            <br />
            <br />
            The below command will help you achieve this:
            <br />
            <b>
              {
                "git reset -soft HEAD~N && git commit -edit -m “$(git log -format=%B -reverse .HEAD@{N})”"
              }
            </b>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-017"}
        onChange={handleExpandAccordion("q-017")}
      >
        <AccordionSummary aria-controls="q-017-content" id="q-017-header">
          <Typography>
            What is the difference between <b>fork</b>, <b>branch</b>, and{" "}
            <b>clone</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label="Differences between fork, branch, and clone">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Fork</TableCell>
                  <TableCell align="center">Branch</TableCell>
                  <TableCell align="center">Clone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    The fork is the process when a copy of the repository is
                    made. It's usually experimentation in the project without
                    affecting the original project. They’re used to advise
                    changes or take inspiration from someone else’s project.
                  </TableCell>
                  <TableCell align="left">
                    Git branches refer to individual projects within a git
                    repository. If there are several branches in a repository,
                    then each branch can have entirely different files and
                    folders.
                  </TableCell>
                  <TableCell align="left">
                    Git clone refers to creating a clone or a copy of an
                    existing git repository in a new directory. Cloning
                    automatically creates a connection that points back to the
                    original repository, which makes it very easy to interact
                    with the central repository.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-018"}
        onChange={handleExpandAccordion("q-018")}
      >
        <AccordionSummary aria-controls="q-018-content" id="q-018-header">
          <Typography>
            How is <b>git merge</b> different from <b>git rebase</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <b>git merge</b> - is used to incorporate new commits into your
            feature branch. It creates an extra merge commit every time you need
            to incorporate changes. It is also polluting your git commits
            history.
            <br />
            <br />
            <b>git rebase</b> - Incorporates all the new commits in the master
            branch. It rewrites the project history by creating brand new
            commits for each commit in the original branch.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-019"}
        onChange={handleExpandAccordion("q-019")}
      >
        <AccordionSummary aria-controls="q-019-content" id="q-019-header">
          <Typography>
            What is the command used to fix a broken commit ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To fix a broken commit in Git, you may use the{" "}
            <b>git commit --amend</b> command, which helps you combine the
            staged changes with the previous commits instead of creating an
            entirely new commit.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-020"}
        onChange={handleExpandAccordion("q-020")}
      >
        <AccordionSummary aria-controls="q-020-content" id="q-020-header">
          <Typography>
            What is <b>git cherry-pick</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The command <b>git cherry-pick</b> enables you to pick up commits
            from a branch within a repository and apply it to another branch.
            This command is useful to undo changes when any commit is
            accidentally made to the wrong branch. Then, you can switch to the
            correct branch and use this command to cherry-pick the commit.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-021"}
        onChange={handleExpandAccordion("q-021")}
      >
        <AccordionSummary aria-controls="q-021-content" id="q-021-header">
          <Typography>
            What is the purpose of the <b>.gitignore</b> file ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The file specifies intentionally untracked files to ignore.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
