import { Divider, Tab, Tabs } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { BreadcrumbPane } from "../components/BreadcrumbPane";
import { TitlePane } from "../components/TitlePane";
import { TabGit } from "./Interview/TabGit";
import { TabJavaScript } from "./Interview/TabJavaScript";
import { TabTypeScript } from "./Interview/TabTypeScript";
import { TabNodeJs } from "./Interview/TabNodeJs";
import { TabReact } from "./Interview/TabReact";
import { TabReactNative } from "./Interview/TabReactNative";

export const Interview = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const renderTabContents = () => {
    switch (activeTab) {
      case 0:
        return <TabGit />;
      case 1:
        return <TabJavaScript />;
      case 2:
        return <TabTypeScript />;
      case 3:
        return <TabNodeJs />;
      case 4:
        return <TabReact />;
      case 5:
        return <TabReactNative />;
      default:
        break;
    }
  };

  return (
    <div className={"ka-wrapper ka-bg-tiles"}>
      <div className={"ka-container"}>
        <BreadcrumbPane breadcrumbText={"Interview"} />
        <TitlePane titleText={"Interview"} />
        <Tabs
          textColor="primary"
          indicatorColor="secondary"
          value={activeTab}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{
            sx: {
              display: "none",
            },
          }}
          sx={{
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap",
            },
          }}
        >
          <Tab label="Git" />
          <Tab label="JavaScript" />
          <Tab label="TypeScript" />
          <Tab label="Node.js" />
          <Tab label="React" />
          <Tab label="React Native" />
        </Tabs>
        <br />
        <Divider />
        <br />
        <div className={"ka-interview-questions"}>{renderTabContents()}</div>
        <div className={"ka-interview-update"}>
          <p>
            <span>Last update:</span> 23th of October, 2022
          </p>
          <img
            className={"ka-logo"}
            src={"/assets/logo/logo_dark.png"}
            alt={"Karmikal Apps"}
          />
        </div>
      </div>
    </div>
  );
};
