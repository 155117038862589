import { SyntheticEvent, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./InterviewHelper";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

export const TabTypeScript = () => {
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    "q-001"
  );

  const handleExpandAccordion =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : false);
    };

  return (
    <div>
      <Accordion
        expanded={expandedAccordion === "q-001"}
        onChange={handleExpandAccordion("q-001")}
      >
        <AccordionSummary aria-controls="q-001-content" id="q-001-header">
          <Typography>What is TypeScript ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            TypeScript is a typed superset of JavaScript that compiles to plain
            JavaScript. It is pure object-oriented with classes, interfaces and
            statically typed programming languages like C# or Java. You will
            need a compiler to compile and generate the code in the JavaScript
            file. Basically, TypeScript is the ES6 version of JavaScript with
            some additional features.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-002"}
        onChange={handleExpandAccordion("q-002")}
      >
        <AccordionSummary aria-controls="q-002-content" id="q-002-header">
          <Typography>
            What are the Differences between TypeScript and JavaScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label="Differences Between TypeScript and JavaScript">
              <TableHead>
                <TableRow>
                  <TableCell align="center">TypeScript</TableCell>
                  <TableCell align="center">JavaScript</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    TypeScript is an <b>Object-Oriented</b> language
                  </TableCell>
                  <TableCell align="center">
                    JavaScript is a <b>Scripting</b> language
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    It has a feature known as <b>Static typing</b>
                  </TableCell>
                  <TableCell align="center">
                    It does not have static typing
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    TypeScript gives support for modules
                  </TableCell>
                  <TableCell align="center">
                    JavaScript does not support modules
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    It supports optional parameter function
                  </TableCell>
                  <TableCell align="center">
                    It does not support optional parameter function
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-003"}
        onChange={handleExpandAccordion("q-003")}
      >
        <AccordionSummary aria-controls="q-003-content" id="q-003-header">
          <Typography>Why should we use TypeScript nowadays ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              <b>Using new features of ECMAScript:</b> TypeScript supports new
              ECMAScript standards and transpile them to ECMAScript targets of
              your choice. So, you can use features of ES2015 and beyond.
            </li>
            <li>
              <b>Static Typing:</b> JavaScript is dynamically typed and does not
              know what type a variable is until it is actually instantiated at
              run-time. TypeScript adds type support to JavaScript.
            </li>
            <li>
              <b>Type Inference:</b> TypeScript makes typing a bit easier and a
              lot less explicit by the usage of type inference. Even if you
              don’t explicitly type the types, they are still there to save you
              from doing something which otherwise would result in a run-time
              error.
            </li>
            <li>
              <b>Better IDE Support:</b> The development experience with
              TypeScript is a great improvement over JavaScript. There is a wide
              range of IDEs that have excellent support for TypeScript, like
              Visual Studio & VS code, Atom, Sublime, and IntelliJ/WebStorm.
            </li>
            <li>
              <b>Strict Null Checking:</b> Errors, like cannot read property ‘x’
              of undefined, is common in JavaScript programming. You can avoid
              most of these kinds of errors since one cannot use a variable that
              is not known to the TypeScript compiler.
            </li>
            <li>
              <b>Interoperability:</b> TypeScript is closely related to
              JavaScript so it has great interoperability capabilities, but some
              extra work is required to work with JavaScript libraries in
              TypeScript.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-004"}
        onChange={handleExpandAccordion("q-004")}
      >
        <AccordionSummary aria-controls="q-004-content" id="q-004-header">
          <Typography>
            Would a valid <b>.js</b> file renamed into <b>.ts</b> still be valid
            ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            TypeScript is a superset of JavaScript, therefore it would still be
            valid.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-005"}
        onChange={handleExpandAccordion("q-005")}
      >
        <AccordionSummary aria-controls="q-005-content" id="q-005-header">
          <Typography>What are the Advantages of using TypeScript ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <li>
              TypeScript is fast, simple, easy to learn and runs on any browser
              or JavaScript engine.
            </li>
            <li>
              It is similar to JavaScript and uses the same syntax and
              semantics.
            </li>
            <li>
              You can call the TypeScript code from an existing JavaScript code.
              Also, it works with existing JavaScript frameworks and libraries
              without any issues.
            </li>
            <li>
              The Definition file, with .d.ts extension, provides support for
              existing JavaScript libraries like Jquery, D3.js, etc.
            </li>
            <li>
              It includes features from ES6 and ES7 that can run in ES5-level
              JavaScript engines like Node.js.
            </li>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-006"}
        onChange={handleExpandAccordion("q-006")}
      >
        <AccordionSummary aria-controls="q-006-content" id="q-006-header">
          <Typography>
            What are the Disadvantages of using TypeScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>TypeScript takes a long time to compile the code.</li>
            <li>It does not support abstract classes.</li>
            <li>
              If we run the TypeScript application in the browser, a compilation
              step is required to transform TypeScript into JavaScript.
            </li>
            <li>
              Web developers are using JavaScript for decades and TypeScript
              doesn’t bring anything new.
            </li>
            <li>
              To use any third party library, the definition file is a must.
            </li>
            <li>Quality of type definition files is a concern.</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-007"}
        onChange={handleExpandAccordion("q-007")}
      >
        <AccordionSummary aria-controls="q-007-content" id="q-007-header">
          <Typography>
            What is the use of the <b>tsconfig.json</b> file ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The <b>tsconfig.json</b> file is a JSON format file where you may
            specify several options to inform the compiler how to compile a
            project. The presence of this file in the directory implies that it
            is the TypeScript project root.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-008"}
        onChange={handleExpandAccordion("q-008")}
      >
        <AccordionSummary aria-controls="q-008-content" id="q-008-header">
          <Typography>What is meant by contextual typing ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            When one side of the equation has type but the other does not, the
            TypeScript compiler determines the form. You can skip typing on the
            right side because TypeScript will figure it out for you. This
            reduces the amount of time spent typing code.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-009"}
        onChange={handleExpandAccordion("q-009")}
      >
        <AccordionSummary aria-controls="q-009-content" id="q-009-header">
          <Typography>What is static typing ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Static typing refers to a compiler that has recognisable variables,
            arguments, and object members at compile time. This aids in the
            early detection of faults.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-010"}
        onChange={handleExpandAccordion("q-010")}
      >
        <AccordionSummary aria-controls="q-010-content" id="q-010-header">
          <Typography>
            What is the <b>declare</b> keyword in TypeScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Since JavaScript lacks a TypeScript declaration, the declare keyword
            is used to include it in a TypeScript file without causing a
            compilation issue. Ambient methods and declarations use the term to
            define a variable that already exists.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-011"}
        onChange={handleExpandAccordion("q-011")}
      >
        <AccordionSummary aria-controls="q-011-content" id="q-011-header">
          <Typography>What is an interface ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The interface specifies the syntax that classes must use. All of the
            members of an interface are implemented by a class that implements
            it. It's possible to refer to it, but not to use it. A type-checking
            interface is used by the TypeScript compiler.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-012"}
        onChange={handleExpandAccordion("q-012")}
      >
        <AccordionSummary aria-controls="q-012-content" id="q-012-header">
          <Typography>What does 'as' mean in TypeScript ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In TypeScript, the 'as' syntax is used for Type assertion. It was
            created because the original syntax was incompatible with JSX. Only
            as-style assertions can be used with JSX and TypeScript.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-013"}
        onChange={handleExpandAccordion("q-013")}
      >
        <AccordionSummary aria-controls="q-013-content" id="q-013-header">
          <Typography>What is meant by type inference ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            When you don't specify an explicit type for a variable, TypeScript
            can infer it. Type inference is the term for this. This is normally
            done during the declaration, when the variables or parameters are
            initialized.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-014"}
        onChange={handleExpandAccordion("q-014")}
      >
        <AccordionSummary aria-controls="q-014-content" id="q-014-header">
          <Typography>What are decorators ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The decorator is a type of declaration that is used to decorate a
            class declaration, method, accessor, property, or argument.
            Decorators take the form @expression, where expression must evaluate
            to a function that will be called with information about the
            decorated declaration when it is called at runtime.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-015"}
        onChange={handleExpandAccordion("q-015")}
      >
        <AccordionSummary aria-controls="q-015-content" id="q-015-header">
          <Typography>What are mixins ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Mixins means combining simpler partial classes is a popular approach
            of constructing classes from reusable components. To extend a base
            class, the design relies on generics and class inheritance. The
            finest mixin support in TypeScript is provided through the class
            expression pattern.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-016"}
        onChange={handleExpandAccordion("q-016")}
      >
        <AccordionSummary aria-controls="q-016-content" id="q-016-header">
          <Typography>
            What is the purpose of the <b>never</b> type in TypeScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            As the name suggests, the never type represents the type of values
            that never occur. For example, a function that never returns a value
            or that always throws an exception can mark its return type as
            never.
            <br />
            <br />
            You might wonder why we need a ‘never’ type when we already have
            ‘void’. Though both types look similar, they represent two very
            different concepts.
            <br />
            <br />A function that doesn't return a value implicitly returns the
            value undefined in JavaScript. Hence, even though we are saying it’s
            not returning anything, it’s returning ‘undefined’. We usually
            ignore the return value in these cases. Such a function is inferred
            to have a void return type in TypeScript.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-017"}
        onChange={handleExpandAccordion("q-017")}
      >
        <AccordionSummary aria-controls="q-017-content" id="q-017-header">
          <Typography>
            What are <b>enums</b> in TypeScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Enums allow us to create named constants. It is a simple way to give
            more friendly names to numeric constant values. An enum is defined
            by the keyword enum, followed by its name and the members.
            <br />
            <br />
            By default, the enums start the numbering at 0. You can override the
            default numbering by explicitly assigning the values to its members.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-018"}
        onChange={handleExpandAccordion("q-018")}
      >
        <AccordionSummary aria-controls="q-018-content" id="q-018-header">
          <Typography>What is parameter destructuring ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Parameter destructing allows a function to unpack the object
            provided as an argument into one or more local variables.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-019"}
        onChange={handleExpandAccordion("q-019")}
      >
        <AccordionSummary aria-controls="q-019-content" id="q-019-header">
          <Typography>
            How does optional chaining work in TypeScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Optional chaining allows you to access properties and call methods
            on them in a chain-like fashion. You can do this using the ‘?.’
            operator.
            <br />
            <br />
            TypeScript immediately stops running some expression if it runs into
            a ‘null’ or ‘undefined’ value and returns ‘undefined’ for the entire
            expression chain.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-020"}
        onChange={handleExpandAccordion("q-020")}
      >
        <AccordionSummary aria-controls="q-020-content" id="q-020-header">
          <Typography>
            What is the purpose of <b>noImplicitAny</b> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Usually, when we don’t provide any type on a variable, TypeScript
            assumes ‘any’ type.
            <br />
            <br />
            noImplicitAny is a compiler option that you set in the tsconfig.json
            file. It forces the TypeScript compiler to raise an error whenever
            it infers a variable is of any type. This prevents us from
            accidentally causing similar errors.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-021"}
        onChange={handleExpandAccordion("q-021")}
      >
        <AccordionSummary aria-controls="q-021-content" id="q-021-header">
          <Typography>What are intersection types ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Intersection types let you combine the members of two or more types
            by using the ‘&’ operator. This allows you to combine existing types
            to get a single type with all the features you need.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-022"}
        onChange={handleExpandAccordion("q-022")}
      >
        <AccordionSummary aria-controls="q-022-content" id="q-022-header">
          <Typography>What are type assertions in TypeScript ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Sometimes, you as a programmer might know more about the type of a
            variable than TypeScript can infer. Usually, this happens when you
            know the type of an object is more specific than its current type.
            In such cases, you can tell the TypeScript compiler not to infer the
            type of the variable by using type assertions.
            <br />
            <br />
            Type assertions are similar to typecasting in other programming
            languages such as C# or Java. However, unlike those languages,
            there’s no runtime penalty of boxing and unboxing variables to fit
            the types. Type assertions simply let the TypeScript compiler know
            the type of the variable.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-023"}
        onChange={handleExpandAccordion("q-023")}
      >
        <AccordionSummary aria-controls="q-023-content" id="q-023-header">
          <Typography>
            How to enforce strict null checks in TypeScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Null pointers are one of the most common sources of unexpected
            runtime errors in programming. TypeScript helps you avoid them to a
            large degree by enforcing strict null checks.
            <br />
            <br />
            You can enforce strict null checks in two ways:
          </Typography>
          <ul>
            <li>
              providing the <b>--strictNullChecks</b> flag to the TypeScript
              (tsc) compiler
            </li>
            <li>
              setting the <b>strictNullChecks</b> property to true in the
              tsconfig.json configuration file
            </li>
          </ul>
          <Typography>
            When the flag is false, TypeScript ignores null and undefined values
            in the code. When it is true, null and undefined have their distinct
            types. The compiler throws a type error if you try to use them where
            a concrete value is expected.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-024"}
        onChange={handleExpandAccordion("q-024")}
      >
        <AccordionSummary aria-controls="q-024-content" id="q-024-header">
          <Typography>
            How to make object properties immutable in TypeScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can mark object properties as immutable by using the readonly
            keyword before the property name. When you mark a property as
            readonly, it can only be set when you initialize the object. Once
            the object is created, you cannot change it.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-025"}
        onChange={handleExpandAccordion("q-025")}
      >
        <AccordionSummary aria-controls="q-025-content" id="q-025-header">
          <Typography>What is a type declaration file ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A typical TypeScript project references other third-party TypeScript
            libraries such as JQuery to perform routine tasks. Having type
            information for the library code helps you in coding by providing
            detailed information about the types, method signatures, etc., and
            provides IntelliSense.
            <br />
            <br />A type declaration file is a text file ending with a{" "}
            <b>.d.ts</b> extension providing a way to declare the existence of
            some types or values without actually providing implementations for
            those values. It contains the type declarations but doesn’t have any
            source code. It doesn’t produce a .js file after compilation.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-026"}
        onChange={handleExpandAccordion("q-026")}
      >
        <AccordionSummary aria-controls="q-026-content" id="q-026-header">
          <Typography>What is the purpose of the ‘in’ operator ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The <b>in</b> operator is used to find if a property is in the
            specified object. It returns true if the property belongs to the
            object. Otherwise, it returns false.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-027"}
        onChange={handleExpandAccordion("q-027")}
      >
        <AccordionSummary aria-controls="q-027-content" id="q-027-header">
          <Typography>
            What are the ‘implements’ clauses in TypeScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            An implements clause is used to check that a class satisfies the
            contract specified by an interface. If a class implements an
            interface and doesn’t implement that interface, the TypeScript
            compiler issues an error.
            <br />
            <br />A class can implement more than one interface. In this case,
            the class has to specify all the contracts of those interfaces.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-028"}
        onChange={handleExpandAccordion("q-028")}
      >
        <AccordionSummary aria-controls="q-028-content" id="q-028-header">
          <Typography>What are string literal types ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In TypeScript, you can refer to specific strings and numbers as
            types.
            <br />
            <br />
            String literal types on their own are not that useful. However, you
            can combine them into unions. This allows you to specify all the
            string values that a variable can take, in turn acting like enums.
            This can be useful for function parameters.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-029"}
        onChange={handleExpandAccordion("q-029")}
      >
        <AccordionSummary aria-controls="q-029-content" id="q-029-header">
          <Typography>What does inheritance mean ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Inheritance allows a class to extend another class and reuse and
            modify the behavior defined in the other class. The class which
            inherits another class is called the derived class, and the class
            getting inherited is called the base class.
            <br />
            <br />
            In TypeScript, a class can only extend one class. TypeScript uses
            the keyword ‘extends’ to specify the relationship between the base
            class and the derived classes.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedAccordion === "q-030"}
        onChange={handleExpandAccordion("q-030")}
      >
        <AccordionSummary aria-controls="q-030-content" id="q-030-header">
          <Typography>
            What utility types are provided by TypeScript ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            TypeScript provides various utility types that make common type
            transformations easy. These utility types are available globally.
            Here are some of the essential utility types included in TypeScript:
          </Typography>
          <br />
          <TableContainer component={Paper}>
            <Table aria-label="Utility types provided by TypeScript">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Utility Type</TableCell>
                  <TableCell align="center">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{"Partial<Type>"}</TableCell>
                  <TableCell align="center">
                    Constructs a type with all properties of Type set to
                    optional.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{"Required<Type>"}</TableCell>
                  <TableCell align="center">
                    Constructs a type consisting of all properties of Type set
                    to required.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{"Readonly<Type>"}</TableCell>
                  <TableCell align="center">
                    Constructs a type with all properties of Type set to
                    readonly.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{"Record<Keys, Type>"}</TableCell>
                  <TableCell align="center">
                    Constructs an object type with property keys are of type
                    Keys, and values are Type.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <h3 className={"centered"}>What is the output of the following ?</h3>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={6}>
            <Card variant="outlined" className={"ka-interview-card"}>
              <SyntaxHighlighter
                language="typescript"
                style={docco}
                className={"ka-syntax-block"}
              >
                {
                  'type Car = {\n  make: string;\n  model: string;\n  year: number | null;\n};\n\nconst newCar: Car = {\n  make: "Ford",\n  model: "Focus Mk-3",\n  year: "2012"\n};\n\nconsole.log(newCar);'
                }
              </SyntaxHighlighter>
              <p>
                <b>
                  <u>Output</u>
                </b>
                <br />
                Type 'string' is not assignable to type 'number'.
              </p>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card variant="outlined" className={"ka-interview-card"}>
              <SyntaxHighlighter
                language="typescript"
                style={docco}
                className={"ka-syntax-block"}
              >
                {
                  "type SuperArray = [string, string, ...string[]];\nconst newArray: SuperArray = ['Test'];\n\nconsole.log(newArray);"
                }
              </SyntaxHighlighter>
              <p>
                <b>
                  <u>Output</u>
                </b>
                <br />
                Type '[string]' is not assignable to type 'SuperArray'.
                <br />
                Source has 1 element(s) but target requires 2.
              </p>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
